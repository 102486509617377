<template>
  <hub-modal :visible="true" class="milestone-modal" closable @close="$emit('close')">
    <template #title
      ><div>{{ milestone.createNew ? 'Create' : 'Edit' }} milestone</div></template
    >
    <form v-if="!isFetchingMilestones" :class="{ dirty: v$.$anyDirty }" autocomplete="off" class="create-milestone-form" @submit.stop.prevent>
      <section class="create-milestone-form-body">
        <div v-if="!canEdit" class="form-row not-enough-rights-label">
          <span> You don't have enough permissions to edit this milestone</span>
        </div>
        <div class="form-row">
          <p-multiselect
            v-model:value="templateId"
            label="Template"
            :options="milestones"
            :disabled="!milestone.createNew || !canEdit"
            :test-id="'milestone-template'"
            @change="onTemplateSelected"
          />
          <ul v-if="v$.templateId.$invalid">
            <li v-if="v$.templateId.required.$invalid" class="error">Template should be selected</li>
          </ul>
        </div>
        <div class="form-row">
          <p-text-field v-model="title" label="Title" autocomplete="off" :test-id="'milestone-title'" :disabled="!canEdit" />
          <ul v-if="v$.title.$invalid">
            <li v-if="v$.title.required.$invalid" class="error">Title is required</li>
          </ul>
        </div>
        <div class="form-row">
          <div class="form-row-action-list">
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneDueAt('+1d')">+1d</p-button>
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneDueAt('+1w')">+1w</p-button>
            <p-button type="button" variant="text" color="primary" :disabled="!canEdit" @click.stop.prevent="setMilestoneDueAt('+1m')">+1m</p-button>
          </div>
          <hub-date v-model:value="dueAt" label="Due At" :disabled="!canEdit" />
        </div>
        <div class="form-row">
          <p-button
            v-if="!(assignees.length === 1 && assignees[0] === email)"
            :disabled="!canEdit"
            class="claim-button"
            variant="text"
            color="primary"
            @click.stop.prevent="claim"
          >
            assign to me
          </p-button>
          <p-assignees v-model:value="assignees" label="Assigned to" placeholder="" :disabled="!canEdit" />
        </div>

        <div v-if="variables.length" class="variables">
          <div class="title">Additional info:</div>
          <div v-for="field of variables.filter(f => f.type === 'people')" :key="field.name" class="form-row">
            <p-assignees v-model:value="field.enteredValue" :label="field.name" placeholder="" :disabled="!canEdit" />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'date')" :key="field.name" class="form-row">
            <hub-date v-model:value="field.enteredValue" :label="field.name" placeholder="" :disabled="!canEdit" />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'datetime')" :key="field.name" class="form-row">
            <hub-date v-model:value="field.enteredValue" :label="field.name" mode="datetime" placeholder="" :disabled="!canEdit" />
          </div>
          <div v-for="field of variables.filter(f => f.type === 'number' || f.type === 'string')" :key="field.name" class="form-row">
            <p-text-field
              v-model="field.enteredValue"
              :label="field.name"
              :test-id="field.name"
              autocomplete="off"
              :multiline="true"
              :disabled="!canEdit"
            />
          </div>
        </div>
      </section>
      <section v-if="canEdit" class="create-milestone-form-footer">
        <div>
          <p-button v-if="!milestone.createNew" type="button" color="secondary" @click.prevent="deleteMilestone">
            Delete
          </p-button>
        </div>
        <div>
          <p-button type="button" :disabled="isCreatingMilestone" @click.prevent="$emit('close')">
            Cancel
          </p-button>
          <p-button
            type="button"
            color="primary"
            :disabled="(v$.$anyDirty && v$.$invalid) || isCreatingMilestone || isUpdatingMilestone"
            @click.prevent="submit"
          >
            {{ milestone.createNew ? 'Create' : 'Update' }}
          </p-button>
        </div>
      </section>
    </form>
    <div v-else class="loader-wrapper">
      <hub-icon name="loading" spin></hub-icon>
    </div>
  </hub-modal>
</template>
<script>
import { mapState } from 'vuex';

import Modal from '@/components/common/Modal';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';

import TextField from '@/components/common/TextField';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { format, parse } from 'date-fns';
import DateField from '@/components/common/DateField';
import Assignees from '@/components/Assignees';
import Icon from '@/components/common/Icon';
export default {
  components: {
    'hub-modal': Modal,
    'p-assignees': Assignees,
    'p-multiselect': Multiselect,
    'p-button': Button,
    'hub-date': DateField,
    'p-text-field': TextField,
    'hub-icon': Icon
  },
  props: {
    invention: {
      type: Object,
      required: true
    },
    milestone: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['close', 'created'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      templateId: null,
      title: this.milestone.title || '',
      dueAt: this.milestone.dueAt ? new Date(this.milestone.dueAt) : null,
      assignees: this.milestone.createNew || !this.milestone.workflow ? [] : [...this.milestone.workflow.milestoneAssignees],
      variables: [],
      canEdit: true
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email,
      milestones: s =>
        s.milestones.collection.map(({ id, title, ...rest }) => {
          return {
            ...rest,
            label: title,
            value: id
          };
        }),
      isCreatingMilestone: s => s.milestones.isCreateRequestPending,
      isFetchingMilestones: s => s.milestones.isGetCollectionRequestPending || s.milestones.getMilestoneInstanceRequestPending,
      milestoneInstance: s => s.milestones.milestoneInstance,
      isUpdatingMilestone: s => s.milestones.isUpdateRequestPending
    })
  },
  async created() {
    const promises = [this.$store.dispatch('milestones/getCollection', this.invention.references)];

    if (this.milestone && !this.milestone.createNew) {
      this.templateId = this.milestone.workflow.milestoneTemplateId;
      promises.push(this.$store.dispatch('milestones/getMilestoneInstance', this.milestone.workflow.milestoneId));
    }

    await Promise.all(promises);

    if (this.milestone && !this.milestone.createNew) {
      const milestone = this.milestones.find(m => m.value === this.milestone.workflow.milestoneTemplateId);
      if (milestone) {
        this.variables =
          milestone.variables
            ?.filter(v => !v.source)
            .map(v => {
              let enteredValue;
              if (v.type === 'date' || v.type === 'datetime') {
                enteredValue = this.milestoneInstance.variables[v.property] ? new Date(this.milestoneInstance.variables[v.property]) : null;
              } else if (v.type === 'people') {
                enteredValue = [...this.milestoneInstance.variables[v.property]];
              } else {
                enteredValue = this.milestoneInstance.variables[v.property];
              }
              return { name: v.name, property: v.property, type: v.type, enteredValue };
            }) || [];

        this.canEdit = this.milestoneInstance.canEdit === undefined || this.milestoneInstance.canEdit;
      }
    }
    if (this.milestone.createNew) {
      this.claim();
    }
  },
  methods: {
    onTemplateSelected(v) {
      const milestone = this.milestones.find(m => m.value === v);
      if (milestone) {
        this.title = milestone.label;
        this.variables =
          milestone.variables?.filter(v => !v.source).map(v => ({ name: v.name, property: v.property, type: v.type, enteredValue: null })) || [];
      }
    },
    claim() {
      this.assignees = [this.email];
    },
    setMilestoneDueAt(mod) {
      const value = this.applyDateMod(mod, new Date());
      if (value) {
        this.dueAt = value;
      }
    },

    applyDateMod(mod, value = new Date()) {
      switch (mod) {
        case '+1d':
          const tomorrow = new Date(value);
          tomorrow.setDate(value.getDate() + 1);
          return tomorrow;
        case '+1w':
          const inWeek = new Date(value);
          inWeek.setDate(value.getDate() + 7);
          return inWeek;
        case '+1m':
          const inMonth = new Date(value);
          inMonth.setMonth(value.getMonth() + 1);
          return inMonth;
        default:
          return null;
      }
    },
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      let variables = {};
      if (this.milestoneInstance) {
        variables = { ...this.milestoneInstance.variables };
      }
      const obj = {
        templateId: this.templateId,
        title: this.title,
        dueAt: this.dueAt ? this.dueAt : null,
        assignees: this.assignees,
        inventionId: this.invention.id,
        variables: {
          ...this.variables.reduce((acc, curr) => {
            acc[curr.property] = curr.enteredValue;
            return acc;
          }, variables)
        }
      };
      if (this.milestone.createNew) {
        await this.$store.dispatch('milestones/create', obj);
      } else {
        await this.$store.dispatch('milestones/update', { id: this.milestone.workflow.milestoneId, ...obj });
      }

      this.$emit('created');
    },
    async deleteMilestone() {
      const confirmResult = await this.$confirm({
        title: 'Delete milestone?',
        message: `Are you sure you want to delete milestone?\n\nThis action can't be undone.`,
        confirm: 'Delete'
      });
      if (confirmResult) {
        await this.$store.dispatch('milestones/delete', this.milestone.workflow.milestoneId);
        this.$emit('close');
      }
    }
  },

  validations() {
    return {
      title: { required },
      templateId: { required }
    };
  }
};
</script>
<style lang="scss">
.milestone-modal {
  .modal {
    min-height: 90%;
    width: 1024px;
    grid-template-rows: max-content minmax(0, 1fr) max-content;
  }
  .modal > div {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .modal > footer {
    padding: 0.25rem 0 0.5rem 0;
  }
  form {
    background: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.milestone-modal {
  .loader-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .create-milestone-form {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 1fr auto;
    padding: 0.5rem 0 0.5rem;
    background: var(--theme-surface);
    min-height: 100%;
    height: 100%;

    .create-milestone-form-body {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows: repeat(12, max-content);
      min-height: 0;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .form-row {
      position: relative;
      margin-bottom: 0.5rem;
      &.center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .claim-button {
        position: absolute;
        right: 0;
        font-size: 0.6rem;
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      .form-row-action-list {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;

        > button {
          font-size: 0.6rem;
          padding-right: 0;
          padding-left: 0;
          margin-left: 0.5rem;
        }
      }
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
      margin: 0;
    }
    &.dirty {
      .error {
        display: block;
      }
    }

    .variables {
      border: 1px solid var(--theme-on-background);
      padding: 15px;
      .title {
        margin-bottom: 20px;
      }
    }
    .create-milestone-form-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      vertical-align: bottom;
      padding: 0.5rem var(--theme-scroll-width) 0.25rem 0;
    }
  }

  .not-enough-rights-label {
    font-style: italic;
    color: var(--theme-warning);
    font-weight: 600;
  }
}
</style>
